import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import "./Gdpr.scss"

const Gdpr = () => {
    return (
        <section id="gdpr" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="gdpr.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="gdpr.texts.first" />
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="gdpr.texts.second" />
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="gdpr.texts.third" />
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="gdpr.texts.fourth" />
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="gdpr.texts.fiveth" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gdpr